var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("img", {
          staticStyle: { "margin-top": "-3%" },
          attrs: {
            src: "https://www.achencybe.com/img/pc/others/9-1.jpg",
            width: "100%",
            height: "100%",
          },
        }),
        _c("div", { staticClass: "text-box" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("art_gallery.title_1")))]),
          _c("div", [_vm._v(_vm._s(_vm.$t("art_gallery.title_2")))]),
          _c("div", [_vm._v(_vm._s(_vm.$t("art_gallery.title_3")))]),
          _c("div", { staticClass: "email" }, [
            _vm._v(_vm._s(_vm.$t("art_gallery.title_4"))),
          ]),
        ]),
        _c("div", { staticClass: "banner-text" }, [
          _c("div", [_vm._v("WELCOME")]),
          _c("div", [_vm._v(_vm._s(_vm.$t("art_gallery.welcome")))]),
        ]),
        _c("img", {
          staticClass: "scroll-banner",
          attrs: { src: "https://www.achencybe.com/img/pc/others/9-2.jpg" },
        }),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }