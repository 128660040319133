<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <img src="https://www.achencybe.com/img/pc/others/9-1.jpg"
           width="100%" height="100%" style="margin-top:-3%;" />
      <div class="text-box">
        <div>{{$t('art_gallery.title_1')}}</div>
        <div>{{$t('art_gallery.title_2')}}</div>
        <div>{{$t('art_gallery.title_3')}}</div>
        <div class="email">{{$t('art_gallery.title_4')}}</div>
      </div>
      <div class="banner-text">
        <div>WELCOME</div>
        <div>{{$t('art_gallery.welcome')}}</div>
      </div>
      <img src="https://www.achencybe.com/img/pc/others/9-2.jpg" class="scroll-banner" />
      <zui-footer />
    </div>
  </Page>

</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";

export default {
  components: { Page, ZuiHeader, ZuiFooter },
  data() {
    return {};
  },
  methods: {}

};
</script>
<style lang="scss" scoped>
.text-box {
  margin-top: 54px;
  color: #fff;
  font-size: 18px;
  line-height: 1.7;
}

.banner-text {
  margin-top: 54px;
  color: #fff;
  font-size: 60px;
  line-height: 1.2;

  div:last-child {
    margin-top: 18px;
    font-size: 16px;
  }
}

.scroll-banner {
  width: 100%;
  padding: 40px 0 120px;
}

@media screen and (max-width: 500px) {
  .text-box {
    margin-top: 32px;
    font-size: 12px;

    .email {
      margin-top: 32px;
      font-size: 20px;
    }
  }
  .banner-text {
    margin-top: 32px;
    font-size: 32px;

    div:last-child {
      margin-top: 18px;
      font-size: 16px;
    }
  }
  .scroll-banner {
    width: 100%;
    padding: 40px 0 60px;
  }
}
</style>
